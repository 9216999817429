<template>
  <div class="p-fluid">
    <div class="p-col-12 p-lg-12">
      <div class="card card-w-title">
        <div class="row manager-title-row">
          <div class="p-col-4 manager-title-custom">{{ title }}</div>
        </div>
        <!-- <div class="note">(*) Nội dung không được bỏ trống</div> -->
        <formEdit
          :key="componentKey"
          :mode="mode"
          @back="backToList"
          @save="saveData"
          :triggerSave.sync="triggerSave"
          :model="models"/>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import DataServices from '@/core/DataServices'
import formEdit from './add'
import gql from "graphql-tag";
export default {
  components: {
    formEdit
  },
  data() {
    return {
      users: null,
      validationErrors: [],
      title: "Create new Recommendation",
      mode: 'add',
      models: {},
      componentKey: 1,
      accountID: '',
      page_transaction:null,
      active_check:true,
      users_clubs_id : '',
      time_zone_local : moment.tz.guess(true),
      triggerSave: false
    }
  },
  async mounted () {
    console.log("this.$route.name:",this.$route.query.page_transaction);
    this.mode = this.$route.name === 'edit' ? 'edit' : this.mode;
    this.title = this.mode === 'edit' ? 'Edit Recommendation' : this.title;
    if (this.$route.params.id !== undefined) await this.getData(this.$route.params.id);
    if (!this.$commonFuction.isEmpty(this.$route.query)){
      if (!this.$commonFuction.isEmpty(this.$route.query.page_transaction)){
        this.page_transaction = this.$route.query.page_transaction;
      }
    }
    //check quyền
    var arr_role = await this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_RECOMMENDATION);
    if (this.mode == 'edit'){
      if (arr_role.indexOf(this.$constants.ROLE.EDIT) < 0){
        this.$router.push({path: this.$constants.URL_ERR.FOBIDDEN});
      }
    }else {
      if (arr_role.indexOf(this.$constants.ROLE.ADD) < 0){
        this.$router.push({path: this.$constants.URL_ERR.FOBIDDEN});
      }
    }
    this.componentKey=(new Date()).getTime();
    console.log("this.componentKeythis.componentKey:",this.componentKey)
  },
  methods: {
    backToList() {
      this.$router.push({ path: '/admin/list-recommendation' });
    },
    UpdateActive(key){
      // console.log("key",key)
      var uid = this.$route.params.id;
      this.$commonFuction.is_loading(true);
      if(key == 'lock'){
        this.active_check = false
      }else{
        this.active_check = true
      }

      let variables = {
        'data_update': {
          'active': this.active_check
        },
        'where_key': {
          'id': {'_eq': this.$route.params.id}
        }
      };
      var that = this;
      this.$apollo.mutate({
        mutation: DataServices.updateData('users'),
        variables: variables
      }).then(async (res) => {
        console.log('Success',res);
        if(key == 'lock'){
          var query_t =  `mutation {
            delete_jwt_token(where: { uid: {_eq: "${uid}"}}) {
              returning {
                id
              }
            }
          }`
          await that.$apollo.mutate({
            mutation: gql(query_t),
          })
        }
        that.$toast.add({ severity: 'success', summary: 'Success', detail: 'Save data successfully', life: 3000 })
      })
      this.$commonFuction.is_loading(false);
      // console.log("this.$route.params.id",this.$route.params.id)
    },
    async saveData(params) {
      console.log('gia tri cua parama'+ params)
      let objects = {
        active: params?.active ?? true,
        category_id : params?.category_id ?? null,
        discount : params?.discount ?? "0",
        discount_from : params?.discount_from ?? null,
        discount_to : params?.discount_to ?? null,
        discount_value : params?.discount_value ?? 0,
        address : params?.address ?? null,
        latitude :  parseFloat(params?.latitude) ?? null,
        longtitude : parseFloat(params?.longtitude) ?? null,
        updated_at: moment.tz(new Date(), this.time_zone_local),
        updated_by: this.$store.getters.user,
      }

      var list_file = params?.list_file ?? [];
      var list_id_file_delete = params?.list_id_file_delete ?? [];

      this.triggerSave = false;
      if(this.mode == 'add'){

        objects.created_at = moment.tz(new Date(), this.time_zone_local);
        objects.created_by = this.$store.getters.user;
        await this.$apollo.mutate({
            mutation: DataServices.insertData('recommendation'),
            variables: {
              objects: objects
            }
        }).then(async (res) => {


            // console.log("res",res);
            if (res.data[Object.keys(res.data)].affected_rows) {

              var returning = res.data[Object.keys(res.data)].returning[0];
              console.log('id return la'+returning.id)
              for (let lang of params.list_lang) {
                console.log("params_idlang",lang.id)
                if (lang.id) {
                  console.log('lang là: ' + lang.id_lang);
                  await this.$apollo.mutate({
                    mutation: DataServices.insertData('recommendation_lang'),
                    variables: {
                      objects: {
                        id_lang: lang.id,
                        recommendation_id: returning.id,
                        name: params.name,
                        description: lang.content
                      },
                    }
                  });
                } else {
                  console.error('Lang ID is missing for:', lang);
                }
              }

              console.log("list_filelist_filelist_filelist_file:",list_file)
              if (list_file.length > 0) {
                console.log('Số lượng file là: ' + list_file.length);

                await this.$CoreService.uploadImages(this.$constants.FOLDER_S3.RECOMMENDATION, list_file).then(async (resImage) => {
                  console.log("resresres=====:", resImage);

                  if (resImage && resImage.length > 0) {
                    // Lặp qua từng hình ảnh đã tải lên
                    for (let i = 0; i < resImage.length; i++) {
                      var objectImage = resImage[i]; // Lấy từng tấm hình
                      await this.$CoreService.updateDataImage(this.$constants.TYPE_IMAGE.RECOMMENDATION, returning.id, objectImage).catch(async error => {
                        console.log('error', error);
                        this.$toast.add({
                          severity: 'error',
                          summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                          detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                          life: 3000
                        });
                        this.$commonFuction.is_loading(false);
                      });
                    }

                    // Sau khi tất cả các hình ảnh đã được cập nhật, thực hiện chuyển hướng
                    this.redirectEdit();
                  }
                });
              } else {
                this.redirectEdit();
              }
              this.redirectEdit();
            }
        }).catch(async error => {
            console.log('error', error);
            this.$toast.add({
                severity: 'error',
                summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                life: 3000
            });
            this.$commonFuction.is_loading(false);
        });

      }else if(this.mode === 'edit'){
        var notification_id = this.$route.params.id;
        // var user_id = this.$route.params.id;
        let variables = {
          'data_update': objects,
          'where_key': {
            'id': {'_eq': this.models.id}
          }
        }
        await this.$apollo.mutate({
            mutation: DataServices.updateData('recommendation'),
            variables: variables
        }).then(async (res) => {
          if (res.data[Object.keys(res.data)].affected_rows) {
            var returning = res.data[Object.keys(res.data)].returning[0];
            // Push dữ liệu vào mảng recommendation_lang_objects
            let recommendation_lang_objects = params.list_lang.map(lang => ({
              id_lang: lang.id,
              recommendation_id: returning.id,  // Sử dụng id từ returning
              name: params.name,
              description: lang.content
            }));
            console.log("recommendation_lang_objects_truoc" + JSON.stringify(recommendation_lang_objects, null, 2));

            // Kiểm tra xem có dữ liệu mới không
            if (recommendation_lang_objects.length > 0) {
              // Kiểm tra xem recommendation_id có giá trị hay không
              if (!returning.id) {
                console.error('Error: recommendation_id is null or undefined');
                return;
              }

              // Sau đó xóa các bản ghi cũ và thêm các bản ghi mới
              var queryUpdate = `
            mutation updateRecommendationLang($recommendation_lang_objects: [recommendation_lang_insert_input!]!) {
                delete_recommendation_lang(where: { recommendation_id: {_eq: "${notification_id}"}}) {
                    returning {
                        id
                    }
                }
                insert_recommendation_lang(
                    objects: $recommendation_lang_objects
                ) {
                    returning {
                        id
                    }
                }
            }
        `;

              let variables = {
                recommendation_lang_objects: recommendation_lang_objects
              };

              await this.$apollo.mutate({
                mutation: gql(queryUpdate),
                variables: variables
              }).then(() => {
                console.log('Cập nhật và thêm mới recommendation_lang thành công.');
              }).catch(error => {
                console.log('Lỗi khi cập nhật recommendation_lang:', error);
              });
            } else {
              console.log('Không có dữ liệu mới để cập nhật hoặc thêm.');
            }
            console.log('list_id_file_deletelist_id_file_delete' + list_id_file_delete);
            if (list_id_file_delete.length > 0){
              await this.$CoreService.deleteDataImageMulti(this.$constants.TYPE_IMAGE.RECOMMENDATION, list_id_file_delete)
            }
            if (list_file.length > 0) {
              console.log('1234124244123412341234' + list_file);

              await this.$CoreService.uploadImages(this.$constants.FOLDER_S3.RECOMMENDATION, list_file).then(async (resImage) => {
                console.log("resresres=====:", resImage);

                if (resImage && resImage.length > 0) {
                  // Lặp qua từng hình ảnh đã tải lên
                  for (let i = 0; i < resImage.length; i++) {
                    var objectImage = resImage[i]; // Lấy từng tấm hình
                    await this.$CoreService.updateDataImage(this.$constants.TYPE_IMAGE.RECOMMENDATION, returning.id, objectImage).catch(async error => {
                      console.log('error', error);
                      this.$toast.add({
                        severity: 'error',
                        summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                        detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                        life: 3000
                      });
                      this.$commonFuction.is_loading(false);
                    });
                  }

                  // Sau khi tất cả các hình ảnh đã được cập nhật, thực hiện chuyển hướng
                  this.redirectEdit();
                }
              });
            } else {
              this.redirectEdit();
            }
            this.redirectEdit();
          }


        }).catch(async error => {
            console.log('error', error);
            this.$toast.add({
                severity: 'error',
                summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                life: 3000
            });
            this.$commonFuction.is_loading(false);
        });
      }
      this.$commonFuction.is_loading(false);
    },
    async redirectEdit() {
      var that = this;
      var router_back = {path: '/admin/list-recommendation'};
      that.$commonFuction.is_loading(false);
      if(that.mode == 'add'){
        that.$router.push(router_back);
      }else{
        if (!that.$commonFuction.isEmpty(router_back)){
          // that.page_transaction = ""+that.page_transaction
          router_back.query = { page_transaction: that.page_transaction.toString() };
        }
        that.$router.push(router_back);
      }
      that.$toast.add({ severity: 'success', summary: 'Success', detail: 'Save data successfully', life: 3000 })
    },
    async getData(id) {
      var {data} = await this.$apollo.query({
        query: DataServices.getList('recommendation'),
        variables: {
          where_key: {
            id: {'_eq': id}
          }
        },
        fetchPolicy: "network-only"
      })
      console.log("datadata:",data);
      var model = data[Object.keys(data)];
      if (model.length > 0) {
        this.models = model[0];
        var dataImage =  await this.$CoreService.getFileMapFile([this.$constants.TYPE_IMAGE.RECOMMENDATION], [this.models.id]);
        this.models["list_file_edit"] = dataImage.listData

      }
      this.componentKey++;
    },
  },
  apollo: {
    
  }
}
</script>

<style lang="scss">
.manager-title-custom {
  color: #1D3118;
  font-size: 24px !important;
  /* margin-bottom: 16px; */
  padding-bottom: 20px;
  /* border-bottom: 1px solid #EEEEEE; */
  font-weight: 700;
  margin-left: 20px;
}
.p-inputnumber-buttons-stacked {
  .p-inputnumber-button-group {
    .p-button.p-button-icon-only { padding: 0 !important; }
  }
}
.note{ letter-spacing: 0px; color: #707070; margin-bottom: 24px; padding-top: 10px;}
.p-formgrid { margin: 0;
  .p-panel-header {color: #000000; font-size: 18px; font-weight: bold; background: #EEEEEE;}
  .p-card-field{ padding: 24px;
    .p-field{
      .p-col-fixed { width: 300px; display: inline-block !important; margin: 0;}
      .p-col-width{width:500px !important; border-radius: 5px;
        &.disabled{background: #EEEEEE; border: 0 none; padding: 8px 10px;}
        .file-select{margin: 0;
          input[type="file"]{display: none;}
          .select-button{display: inline-block; cursor: pointer; background: transparent; border-radius: 5px;  color: #000000; font-size: 13px; padding: 3px 16px;  border: 1px solid #B4B4B4;}
        }
        &.pac-target-input{ height: 80px;  word-break: break-all; overflow-wrap: break-word;  resize: none;}
      }
      .box-images{ margin: 0; margin-top: 10px; position: relative;
        .item-img{width: 150px; height: 150px;  margin-right: 8px; margin-bottom: 8px; display: inline-flex; position: relative;
          img{max-width: 100%; height: auto;margin: 0 auto; object-fit: contain; padding: 5px;}
          .button-clear{border-radius: 10px; width: 24px; height: 24px; background: #F5564E; float: right; cursor: pointer; position: absolute; right: 0px;
            img{vertical-align: top;}
          }
        }
      }
      .p-invalid{display: block;}
    }
  }
  .fixed-bottom-toolbar{background: transparent; border: 0 none; margin-top: 22px; border-top: 1px solid #B4B4B4; padding: 0; padding-top: 30px;
    .p-button{width: auto; margin-right: 8px;
      &:last-child{margin-right: 0;}
    }
  }
}
.manager-title-row{
  border-bottom: 1px solid #EEEEEE;
}
.p-toolbar-group-right {
  flex-basis: 30%;
}
.p-button-warning.p-button {
  margin: 0 10px;
}
</style>